import React, { Ref } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { FormControl, InputAdornment, TextFieldProps } from '@mui/material';
import { formatAmount } from '@shared/utils/metals';
import classNames from 'classnames';

import { StyledFormHelperText, StyledTextField, styles } from './input-currency.styles';

export type InputCurrencyFieldProps = AppWithStyles<any> &
  TextFieldProps & {
    resetValidation?: () => void;
    children?: React.ReactNode;
    currency: string;
    defaultValue?: string;
    onChangeValue?: (value: string) => void;
    value?: string;
    digitsCountAfterDot?: number;
    inputRef?: Ref<any>;
    disabled?: boolean;
    error?: boolean;
    errorText?: string;
    text?: string;
    placeholder?: string;
  };

const InputCurrencyComponent: React.FC<InputCurrencyFieldProps> = ({
  classes,
  resetValidation,
  defaultValue,
  helperText,
  error,
  autoFocus,
  multiline,
  maxRows,
  currency,
  onChangeValue,
  value,
  digitsCountAfterDot,
  inputRef,
  disabled,
  text,
  errorText,
  label,
  placeholder,
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValidation && resetValidation();
    e.target.value = formatAmount(e.target.value, digitsCountAfterDot || 2);
    onChangeValue && onChangeValue(e.target.value);
  };

  const getAndronment = () => {
    return (
      <InputAdornment
        position="start"
        style={{
          position: 'relative',
          marginLeft: '6px',
        }}
      >
        <div className={classes.legend}>
          <div className={classes.currency}>{currency}</div>
          <div className={classes.legendText}>
            <span className={classes.red}>{text}</span>
            <span className={classes.white}>{label}</span>
          </div>
        </div>
      </InputAdornment>
    );
  };

  return (
    <FormControl className={classes.control} error={error} hiddenLabel>
      <div
        className={classNames(
          classes.inputBlock,
          { ['error']: error },
          { [classes.error as string]: error },
        )}
      >
        <StyledTextField
          className={classes.input}
          autoComplete={'off'}
          autoFocus={autoFocus}
          disabled={disabled}
          onChange={handleOnChange}
          inputRef={inputRef}
          variant={'outlined'}
          type={'text'}
          multiline={multiline || false}
          maxRows={maxRows || 0}
          value={errorText ? errorText : value}
          InputProps={{
            startAdornment: getAndronment(),
          }}
          defaultValue={defaultValue}
          placeholder={placeholder || '0.00'}
        />
      </div>
      {helperText && helperText !== 'empty' && (
        <StyledFormHelperText>{helperText}</StyledFormHelperText>
      )}
    </FormControl>
  );
};

export const InputCurrency = appWithStyles(styles)(InputCurrencyComponent);
