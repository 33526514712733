import AD from './ad.svg';
import AE from './ae.svg';
import AF from './af.svg';
import AG from './ag.svg';
import AI from './ai.svg';
import AL from './al.svg';
import AM from './am.svg';
import AO from './ao.svg';
import AQ from './aq.svg';
import AR from './ar.svg';
import AS from './as.svg';
import AT from './at.svg';
import AU from './au.svg';
import AW from './aw.svg';
import AX from './ax.svg';
import AZ from './az.svg';
import BA from './ba.svg';
import BB from './bb.svg';
import BD from './bd.svg';
import BE from './be.svg';
import BF from './bf.svg';
import BG from './bg.svg';
import BH from './bh.svg';
import BI from './bi.svg';
import BJ from './bj.svg';
import BL from './bl.svg';
import BM from './bm.svg';
import BN from './bn.svg';
import BO from './bo.svg';
import BQ from './bq.svg';
import BR from './br.svg';
import BS from './bs.svg';
import BT from './bt.svg';
import BV from './bv.svg';
import BW from './bw.svg';
import BY from './by.svg';
import BZ from './bz.svg';
import CA from './ca.svg';
import CC from './cc.svg';
import CD from './cd.svg';
import CF from './cf.svg';
import CG from './cg.svg';
import CH from './ch.svg';
import CI from './ci.svg';
import CK from './ck.svg';
import CL from './cl.svg';
import CM from './cm.svg';
import CN from './cn.svg';
import CO from './co.svg';
import CR from './cr.svg';
import CU from './cu.svg';
import CV from './cv.svg';
import CW from './cw.svg';
import CX from './cx.svg';
import CY from './cy.svg';
import CZ from './cz.svg';
import DE from './de.svg';
import DJ from './dj.svg';
import DK from './dk.svg';
import DM from './dm.svg';
import DO from './do.svg';
import DZ from './dz.svg';
import EC from './ec.svg';
import EE from './ee.svg';
import EG from './eg.svg';
import EH from './eh.svg';
import ER from './er.svg';
import ES from './es.svg';
import ET from './et.svg';
import FI from './fi.svg';
import FJ from './fj.svg';
import FK from './fk.svg';
import FM from './fm.svg';
import FO from './fo.svg';
import FR from './fr.svg';
import GA from './ga.svg';
import GB from './gb.svg';
import GD from './gd.svg';
import GE from './ge.svg';
import GF from './gf.svg';
import GG from './gg.svg';
import GH from './gh.svg';
import GI from './gi.svg';
import GL from './gl.svg';
import GM from './gm.svg';
import GN from './gn.svg';
import GP from './gp.svg';
import GQ from './gq.svg';
import GR from './gr.svg';
import GS from './gs.svg';
import GT from './gt.svg';
import GU from './gu.svg';
import GW from './gw.svg';
import GY from './gy.svg';
import HK from './hk.svg';
import HM from './hm.svg';
import HN from './hn.svg';
import HR from './hr.svg';
import HT from './ht.svg';
import HU from './hu.svg';
import ID from './id.svg';
import IE from './ie.svg';
import IL from './il.svg';
import IM from './im.svg';
import IN from './in.svg';
import IO from './io.svg';
import IQ from './iq.svg';
import IR from './ir.svg';
import IS from './is.svg';
import IT from './it.svg';
import JE from './je.svg';
import JM from './jm.svg';
import JO from './jo.svg';
import JP from './jp.svg';
import KE from './ke.svg';
import KG from './kg.svg';
import KH from './kh.svg';
import KI from './ki.svg';
import KM from './km.svg';
import KN from './kn.svg';
import KP from './kp.svg';
import KR from './kr.svg';
import KW from './kw.svg';
import KY from './ky.svg';
import KZ from './kz.svg';
import LA from './la.svg';
import LB from './lb.svg';
import LC from './lc.svg';
import LI from './li.svg';
import LK from './lk.svg';
import LR from './lr.svg';
import LS from './ls.svg';
import LT from './lt.svg';
import LU from './lu.svg';
import LV from './lv.svg';
import LY from './ly.svg';
import MA from './ma.svg';
import MC from './mc.svg';
import MD from './md.svg';
import ME from './me.svg';
import MF from './mf.svg';
import MG from './mg.svg';
import MH from './mh.svg';
import MK from './mk.svg';
import ML from './ml.svg';
import MM from './mm.svg';
import MN from './mn.svg';
import MO from './mo.svg';
import MP from './mp.svg';
import MQ from './mq.svg';
import MR from './mr.svg';
import MS from './ms.svg';
import MT from './mt.svg';
import MU from './mu.svg';
import MV from './mv.svg';
import MW from './mw.svg';
import MX from './mx.svg';
import MY from './my.svg';
import MZ from './mz.svg';
import NA from './na.svg';
import NC from './nc.svg';
import NE from './ne.svg';
import NF from './nf.svg';
import NG from './ng.svg';
import NI from './ni.svg';
import NL from './nl.svg';
import NO from './no.svg';
import NP from './np.svg';
import NR from './nr.svg';
import NU from './nu.svg';
import NZ from './nz.svg';
import OM from './om.svg';
import PA from './pa.svg';
import PE from './pe.svg';
import PF from './pf.svg';
import PG from './pg.svg';
import PH from './ph.svg';
import PK from './pk.svg';
import PL from './pl.svg';
import PM from './pm.svg';
import PN from './pn.svg';
import PR from './pr.svg';
import PS from './ps.svg';
import PT from './pt.svg';
import PW from './pw.svg';
import PY from './py.svg';
import QA from './qa.svg';
import RE from './re.svg';
import RO from './ro.svg';
import RS from './rs.svg';
import RU from './ru.svg';
import RW from './rw.svg';
import SA from './sa.svg';
import SB from './sb.svg';
import SC from './sc.svg';
import SD from './sd.svg';
import SE from './se.svg';
import SG from './sg.svg';
import SH from './sh.svg';
import SI from './si.svg';
import SJ from './sj.svg';
import SK from './sk.svg';
import SL from './sl.svg';
import SM from './sm.svg';
import SN from './sn.svg';
import SO from './so.svg';
import SR from './sr.svg';
import SS from './ss.svg';
import ST from './st.svg';
import SV from './sv.svg';
import SX from './sx.svg';
import SY from './sy.svg';
import SZ from './sz.svg';
import TC from './tc.svg';
import TD from './td.svg';
import TF from './tf.svg';
import TG from './tg.svg';
import TH from './th.svg';
import TJ from './tj.svg';
import TK from './tk.svg';
import TL from './tl.svg';
import TM from './tm.svg';
import TN from './tn.svg';
import TO from './to.svg';
import TR from './tr.svg';
import TT from './tt.svg';
import TV from './tv.svg';
import TW from './tw.svg';
import TZ from './tz.svg';
import UA from './ua.svg';
import UG from './ug.svg';
import UM from './um.svg';
import US from './us.svg';
import UY from './uy.svg';
import UZ from './uz.svg';
import VA from './va.svg';
import VC from './vc.svg';
import VE from './ve.svg';
import VG from './vg.svg';
import VI from './vi.svg';
import VN from './vn.svg';
import VU from './vu.svg';
import WF from './wf.svg';
import WS from './ws.svg';
import YE from './ye.svg';
import YT from './yt.svg';
import ZA from './za.svg';
import ZM from './zm.svg';
import ZW from './zw.svg';
export const FLAGS: any = {
  ad: AD,
  ae: AE,
  af: AF,
  ag: AG,
  ai: AI,
  al: AL,
  am: AM,
  ao: AO,
  aq: AQ,
  ar: AR,
  as: AS,
  at: AT,
  au: AU,
  aw: AW,
  ax: AX,
  az: AZ,
  ba: BA,
  bb: BB,
  bd: BD,
  be: BE,
  bf: BF,
  bg: BG,
  bh: BH,
  bi: BI,
  bj: BJ,
  bl: BL,
  bm: BM,
  bn: BN,
  bo: BO,
  bq: BQ,
  br: BR,
  bs: BS,
  bt: BT,
  bv: BV,
  bw: BW,
  by: BY,
  bz: BZ,
  ca: CA,
  cc: CC,
  cd: CD,
  cf: CF,
  cg: CG,
  ch: CH,
  ci: CI,
  ck: CK,
  cl: CL,
  cm: CM,
  cn: CN,
  co: CO,
  cr: CR,
  cu: CU,
  cv: CV,
  cw: CW,
  cx: CX,
  cy: CY,
  cz: CZ,
  de: DE,
  dj: DJ,
  dk: DK,
  dm: DM,
  do: DO,
  dz: DZ,
  ec: EC,
  ee: EE,
  eg: EG,
  eh: EH,
  er: ER,
  es: ES,
  et: ET,
  fi: FI,
  fj: FJ,
  fk: FK,
  fm: FM,
  fo: FO,
  fr: FR,
  ga: GA,
  gb: GB,
  gd: GD,
  ge: GE,
  gf: GF,
  gg: GG,
  gh: GH,
  gi: GI,
  gl: GL,
  gm: GM,
  gn: GN,
  gp: GP,
  gq: GQ,
  gr: GR,
  gs: GS,
  gt: GT,
  gu: GU,
  gw: GW,
  gy: GY,
  hk: HK,
  hm: HM,
  hn: HN,
  hr: HR,
  ht: HT,
  hu: HU,
  id: ID,
  ie: IE,
  il: IL,
  im: IM,
  in: IN,
  io: IO,
  iq: IQ,
  ir: IR,
  is: IS,
  it: IT,
  je: JE,
  jm: JM,
  jo: JO,
  jp: JP,
  ke: KE,
  kg: KG,
  kh: KH,
  ki: KI,
  km: KM,
  kn: KN,
  kp: KP,
  kr: KR,
  kw: KW,
  ky: KY,
  kz: KZ,
  la: LA,
  lb: LB,
  lc: LC,
  li: LI,
  lk: LK,
  lr: LR,
  ls: LS,
  lt: LT,
  lu: LU,
  lv: LV,
  ly: LY,
  ma: MA,
  mc: MC,
  md: MD,
  me: ME,
  mf: MF,
  mg: MG,
  mh: MH,
  mk: MK,
  ml: ML,
  mm: MM,
  mn: MN,
  mo: MO,
  mp: MP,
  mq: MQ,
  mr: MR,
  ms: MS,
  mt: MT,
  mu: MU,
  mv: MV,
  mw: MW,
  mx: MX,
  my: MY,
  mz: MZ,
  na: NA,
  nc: NC,
  ne: NE,
  nf: NF,
  ng: NG,
  ni: NI,
  nl: NL,
  no: NO,
  np: NP,
  nr: NR,
  nu: NU,
  nz: NZ,
  om: OM,
  pa: PA,
  pe: PE,
  pf: PF,
  pg: PG,
  ph: PH,
  pk: PK,
  pl: PL,
  pm: PM,
  pn: PN,
  pr: PR,
  ps: PS,
  pt: PT,
  pw: PW,
  py: PY,
  qa: QA,
  re: RE,
  ro: RO,
  rs: RS,
  ru: RU,
  rw: RW,
  sa: SA,
  sb: SB,
  sc: SC,
  sd: SD,
  se: SE,
  sg: SG,
  sh: SH,
  si: SI,
  sj: SJ,
  sk: SK,
  sl: SL,
  sm: SM,
  sn: SN,
  so: SO,
  sr: SR,
  ss: SS,
  st: ST,
  sv: SV,
  sx: SX,
  sy: SY,
  sz: SZ,
  tc: TC,
  td: TD,
  tf: TF,
  tg: TG,
  th: TH,
  tj: TJ,
  tk: TK,
  tl: TL,
  tm: TM,
  tn: TN,
  to: TO,
  tr: TR,
  tt: TT,
  tv: TV,
  tw: TW,
  tz: TZ,
  ua: UA,
  ug: UG,
  um: UM,
  us: US,
  uy: UY,
  uz: UZ,
  va: VA,
  vc: VC,
  ve: VE,
  vg: VG,
  vi: VI,
  vn: VN,
  vu: VU,
  wf: WF,
  ws: WS,
  ye: YE,
  yt: YT,
  za: ZA,
  zm: ZM,
  zw: ZW,
};
