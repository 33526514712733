import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { FormHelperText, TextField, Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export const styles = (theme: Theme) =>
  appCreateStyles({
    root: {
      width: '100%',
    },
    inputBlock: {
      '& input': {
        textAlign: 'end',
        color: _var(CSS_VARIABLES.colorPrimary100),
        [theme.breakpoints.up(Layout.laptop)]: {
          color: '#fff',
        },
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '26px',
        letterSpacing: '0.09px',
        height: '67px',
        boxSizing: 'border-box',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0px',
      },
    },
    error: {
      '&$inputBlock input': {
        color: '#C72C2C !important',
        '-webkit-text-fill-color': '#C72C2C !important',
      },

      '&$inputBlock input.Mui-disabled': {
        color: '#C72C2C !important',
        '-webkit-text-fill-color': '#C72C2C !important',
      },
    },
    legend: {
      display: 'flex',
      flexDirection: 'column',
    },
    currency: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.08px',
    },
    legendText: {
      color: '#5A5B71',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '0.06px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
      '$error & .red': {
        color: '#C72C2C !important',
      },
      '$error & .white': {
        color: '#5A5B71',
      },
    },
  });

export const StyledTextField = appWithStyles((theme: Theme) => ({
  root: {
    borderRadius: '12px',
    background: '#1E1D36',
    border: 'none',
    [theme.breakpoints.up(Layout.laptop)]: {
      border: '1px solid #43335F',
      background: '#372753',
      '.error &': {
        border: '1px solid #C72C2C;',
      },
    },

    '& input.Mui-disabled': {
      '-webkit-text-fill-color': _var(CSS_VARIABLES.colorPrimary100),
    },
  },
  focused: {},
}))(TextField);

export const StyledFormHelperText = appWithStyles(() => ({
  root: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '165%',
    letterSpacing: '0.2px',
    marginLeft: '0px',
    marginRight: '0px',
    top: '64px',
  },
}))(FormHelperText);
